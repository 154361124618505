import React, { useState } from 'react';
import { useCreatePath, useDelete, useNotify, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { CancelEditModal, DeleteModal } from 'src/components/Children/modals';
import { UserRole } from 'src/auth/types';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { ShsButton, WithRole } from 'src/components/common';
import { Resource } from 'src/constants';
import { shsButtonStyles } from 'src/styles';

import ShsFormToolbar from '../../common/ShsFormToolbar';
import { ValidationErrorModal } from '../modals/ValidationErrorModal';

/**
 * Props for the AddToolbar component
 * @property { boolean } [editMode] - Indicates if the toolbar is in edit mode
 * @property { () => void } [handleCancel] - Function to call when canceling an action
 */
interface AddToolbarProps {
    editMode?: boolean;
    handleCancel?: () => void;
}

/**
 * A toolbar component for adding or editing entries
 * It provides buttons for saving, canceling, and deleting entries
 */
const AddToolbar: React.FC<AddToolbarProps> = ({ handleCancel, editMode = false }) => {
    const record = useRecordContext();
    const createPath = useCreatePath();
    const notify = useNotify();
    const [deleteOne] = useDelete();
    const navigate = useNavigate();
    const [isValidationErrorModalOpen, setValidationErrorModalOpen] = useState(false);
    const [isCancelModalOpen, setCancelModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    /**
     * Opens the cancel confirmation modal
     */
    const handleCancelConfirm = () => {
        setCancelModalOpen(true);
    };

    /**
     * Closes the cancel confirmation modal
     */
    const handleCloseCancelModal = () => {
        setCancelModalOpen(false);
    };

    /**
     * Closes the delete confirmation modal
     */
    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    /**
     * Handles form validation error
     * Opens validation error modal
     */
    const handleValidationError = () => {
        handleCloseCancelModal();
        setValidationErrorModalOpen(true);
    };

    /**
     * Closes the validation error modal
     */
    const handleCloseValidationErrorModal = () => {
        setValidationErrorModalOpen(false);
    };

    /**
     * Handles the click event for the delete button
     */
    const handleDeleteBtnClick = () => {
        deleteOne(
            Resource.CHILDREN,
            { id: record.id },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    notify('Видалення дитини пройшло успішно', {
                        type: 'success',
                        autoHideDuration: 2500
                    });
                    navigate(createPath({ resource: 'children', type: 'list' }));
                },
                onError: (error: any) => {
                    notify(`Помилка: ${error?.message}`, {
                        type: 'error'
                    });
                }
            }
        );
        setDeleteModalOpen(false);
    };

    /**
     * Closes the delete confirmation modal
     */
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <ShsFormToolbar
                    enableSaveButton={true}
                    handleCancel={handleCancelConfirm}
                    sx={shsButtonStyles}
                    shouldCheckIfDirty={false}
                />

                {editMode && (
                    <WithRole roles={[UserRole.SYSTEM_ADMIN]}>
                        <ShsButton
                            label={'Bидалити'}
                            sx={{
                                marginTop: '28px'
                            }}
                            styleType="outline-danger"
                            icon={<DeleteIcon style={{ fill: '#F23030' }} />}
                            onClick={handleOpenDeleteModal}
                        />
                    </WithRole>
                )}
            </Box>

            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                onCancel={handleCancel}
                onConfirm={handleDeleteBtnClick}
            />

            <CancelEditModal
                isOpen={isCancelModalOpen}
                isEdit={editMode}
                onClose={handleCloseCancelModal}
                handleValidationError={handleValidationError}
                onCancel={handleCancel}
            />

            {isValidationErrorModalOpen && (
                <ValidationErrorModal isOpen={isValidationErrorModalOpen} onClose={handleCloseValidationErrorModal} />
            )}
        </>
    );
};

export default AddToolbar;
