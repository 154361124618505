import React, { useState } from 'react';
import { useCreatePath, useNotify, useRecordContext, useRedirect, useUpdate, useRefresh } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';
import { Stack, Theme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

import { ShsButton, WithRole } from '../../common';
import { ReactComponent as DeleteIcon } from '../../../assets/trash-can.svg';
import { Resource } from '../../../constants';
import { palette } from '../../../Theme';
import { DeleteLawConfirmation } from './DeleteLawConfrimation';
import { UserRole } from 'src/auth/types';

export const EditLawActionToolbar = () => {
    const record = useRecordContext();
    const { getValues } = useFormContext();
    const { isDirty, isValid } = useFormState();
    const notify = useNotify();
    const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [update] = useUpdate();
    const [modalOpen, setModalOpen] = useState(false);
    const createPath = useCreatePath();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleEditBtnClick = async () => {
        const { name, link } = getValues();
        try {
            await update(Resource.LAWS, {
                id: record.id,
                data: {
                    id: record.id,
                    version: record.version,
                    name,
                    link
                }
            });

            notify('Законопроєкт успішно відредаговано', {
                type: 'success',
                autoHideDuration: 2500
            });

            refresh();
            redirect(createPath({ resource: Resource.LAWS, type: 'list' }));
        } catch (error: any) {
            notify(`Помилка: ${error?.message}`, {
                type: 'error'
            });
        }
    };

    const handleCancelBtnClick = () => {
        redirect(createPath({ resource: 'laws', type: 'list' }));
    };

    const handleDeleteBtnClick = () => {
        setModalOpen(true);
    };

    return (
        <Stack direction={'row'} spacing={1.5} useFlexGap={true} sx={{ width: '100%' }}>
            <ShsButton
                label="Зберегти"
                onClick={handleEditBtnClick}
                disabled={!isDirty || !isValid}
                icon={isXSmall ? <EditIcon /> : undefined}
            />
            <ShsButton
                label="Скасувати"
                styleType="outline"
                sx={{ marginRight: 'auto' }}
                onClick={handleCancelBtnClick}
                icon={isXSmall ? <ArrowBackIcon /> : undefined}
            />
            <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT]}>
                <ShsButton
                    label="Видалити"
                    icon={<DeleteIcon fill={palette.error.main} />}
                    styleType="outline-danger"
                    onClick={handleDeleteBtnClick}
                />
            </WithRole>
            <DeleteLawConfirmation modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </Stack>
    );
};
