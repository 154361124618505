import { FC, useEffect, useState } from 'react';
import { Calendar, Views } from 'react-big-calendar';
import localizer, { getCulture } from '../../../common/ShsCalendar/config';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Toolbar } from 'src/components/common/ShsCalendar/components';
import { ScheduleEventCard } from '../../common/components/ScheduleEventCard';
import { styled } from '@mui/system';
import { ScheduleCalendarEvent } from './ScheduleCalendar.types';
import { DragAndDropData } from '../../common/interfaces/DragAndDrop.interface';
import { useWatch } from 'react-hook-form';
import { EducationLevelGroup } from 'src/constants';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { ScheduleBooking } from '../../common/interfaces/ScheduleBooking.interface';
import { TimeSlotWithDrop } from './TimeSlotWithDrop';
import { CalendarRange } from '../../common/context/CalendarContext.interface';
import { GradeRangeToLessonDuration } from '../../common/const/Calendar.const';

const CalendarCellDimensions = {
    width: 150,
    height: 70
};

const StyledCalendar = styled(Calendar)({
    minHeight: '800px',
    '.rbc-event': {
        border: 'none',
        padding: 0,
        '&.rbc-selected:focus': {
            outline: '5px auto currentColor'
        }
    },
    '.rbc-time-view': {
        '.rbc-event-label': {
            display: 'none'
        },
        '.rbc-events-container': {
            position: 'static'
        },
        '.rbc-timeslot-group': {
            flexBasis: 'auto',
            height: CalendarCellDimensions.height,
            minWidth: CalendarCellDimensions.width
        },
        '.rbc-time-header-gutter': {
            minWidth: CalendarCellDimensions.width
        },
        '.rbc-day-bg': {
            minWidth: CalendarCellDimensions.width - 1 // border
        },
        '.rbc-header': {
            minWidth: CalendarCellDimensions.width - 1 // border
        }
    }
});

interface ScheduleCalendarProps {
    events: ScheduleCalendarEvent[];
    activeTab: EducationLevelGroup;
}

export const ScheduleCalendar: FC<ScheduleCalendarProps> = ({ events, activeTab }) => {
    const { calendarData, calendarConfig, setCalendarConfig } = useScheduleCalendarContext();
    const [calendarEvents, setCalendarEvents] = useState<ScheduleCalendarEvent[]>([]);

    const lessonDuration = GradeRangeToLessonDuration[calendarConfig.educationLevelGroup];

    const selectedEducationGroupLevels = useWatch({
        name: 'educationLevelGroup',
        defaultValue: []
    });

    const bookingInfo: ScheduleBooking[] = [];
    if (calendarData?.children) {
        calendarData.children.forEach((child) => bookingInfo.push(...child.bookingInfo));
    }
    if (calendarData?.employees) {
        calendarData.employees.forEach((employee) => bookingInfo.push(...employee.bookingInfo));
    }

    useEffect(() => {
        if (calendarConfig.viewMode) {
            events = events.filter((calendarEvent) =>
                calendarEvent.educationLevels?.some((value) => selectedEducationGroupLevels.includes(value))
            );
        }

        setCalendarEvents(Array.isArray(events) ? events : []);
    }, [events, selectedEducationGroupLevels, calendarConfig]);

    function handleScheduleEventDragEnd({ item }: DragAndDropData) {
        setCalendarEvents((calendarEvents) => calendarEvents.filter((calendarEvent) => calendarEvent.id !== item.id));
    }

    const handleTimeSlotDrop = (slotDate: Date, dropEvent: ScheduleCalendarEvent) => {
        const calendarEventIndex = calendarEvents.findIndex((calendarEvent) => calendarEvent.id === dropEvent.id);
        const eventStart = slotDate;
        let eventEnd;

        if (dropEvent.end && dropEvent.start) {
            const eventDuration = dropEvent.end.getTime() - dropEvent.start.getTime();
            eventEnd = new Date(eventStart.getTime() + eventDuration);
        } else {
            eventEnd = new Date(slotDate.getTime() + lessonDuration * 1000 * 60);
        }

        // todo: replace this logic with the call to API
        if (calendarEventIndex !== -1) {
            const updatedEvent = {
                ...calendarEvents[calendarEventIndex],
                start: eventStart,
                end: eventEnd,
                id: crypto.randomUUID()
            };

            setCalendarEvents((calendarEvents) =>
                calendarEvents.map((event, index) => (index === calendarEventIndex ? updatedEvent : event))
            );
        } else {
            const newEvent = {
                ...dropEvent,
                start: slotDate,
                end: new Date(slotDate.getTime() + lessonDuration * 1000 * 60)
            };

            setCalendarEvents((calendarEvents) => [...calendarEvents, newEvent]);
        }
    };

    const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
        const calendarRange: CalendarRange = { start: new Date(), end: new Date() };

        if (Array.isArray(range)) {
            calendarRange.start = range[0];
            calendarRange.end = range[range.length - 1];
        } else {
            calendarRange.start = range.start;
            calendarRange.end = range.end;
        }

        setCalendarConfig((config) => ({ ...config, range: calendarRange }));
    };

    return (
        <StyledCalendar
            localizer={localizer}
            events={calendarEvents}
            selectable={false}
            defaultView={Views.WEEK}
            timeslots={1}
            min={new Date(0, 0, 0, 8, 0, 0)}
            max={new Date(0, 0, 0, 20, 0, 0)}
            step={lessonDuration}
            formats={{
                timeGutterFormat: 'HH:mm'
            }}
            culture={getCulture('uk')}
            onRangeChange={handleRangeChange}
            components={{
                toolbar: (props) => <Toolbar {...props} activeTab={calendarConfig.viewMode ? activeTab : undefined} />,
                event: ({ event }) => (
                    <ScheduleEventCard event={event as ScheduleCalendarEvent} onDragEnd={handleScheduleEventDragEnd} />
                ),
                timeSlotWrapper: (props) => (
                    <TimeSlotWithDrop {...props} onDrop={handleTimeSlotDrop} bookingInfo={bookingInfo} />
                )
            }}
        />
    );
};
