import { ComponentProps, FC, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { DragItem, DragAndDropZone } from 'src/constants/drag-and-drop';
import { ScheduleCalendarEvent } from './ScheduleCalendar.types';
import { useWatch } from 'react-hook-form';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { ScheduleBooking } from '../../common/interfaces/ScheduleBooking.interface';
import DateTimeUtils from 'src/utils/datetime';
import { palette } from 'src/Theme';
import { hashCode } from 'src/utils';
import styled from '@emotion/styled';
import { GradeRangeToLessonDuration } from '../../common/const/Calendar.const';

interface TimeSlotProps extends ComponentProps<any> {
    bookingInfo: ScheduleBooking[];
}

interface StyledTimeSlotProps extends ComponentProps<any> {
    title?: string;
    highlighted?: boolean;
    isTimeBooked?: boolean;
    isTimeGutter?: boolean;
    isBreakTime?: boolean;
    isWorkTime?: boolean;
    isViewMode?: boolean;
}

export const StyledTimeSlot = styled(
    'div',
    {}
)<StyledTimeSlotProps>(({ title, highlighted, isTimeBooked, isTimeGutter, isViewMode, isBreakTime, isWorkTime }) => {
    let background = 'transparent';
    let opacity = 1;

    if (isTimeGutter || isViewMode) {
        background = 'transparent';
    } else if (isBreakTime || !isWorkTime) {
        background = palette.primary.grayTint;
    } else if (isTimeBooked) {
        background = 'repeating-linear-gradient( -45deg, #9fa2a6 0, #9fa2a6 2px, #e8ebef 0, #e8ebef 6px )';
    } else if (isWorkTime && highlighted) {
        background = palette.lessonColors[Math.abs(hashCode(title?.toString() || '')) % palette.lessonColors.length];
        opacity = 0.2;
    }

    return {
        width: '100%',
        height: '100%',
        background,
        opacity
    };
});

export const TimeSlotWithDrop: FC<TimeSlotProps> = ({ resource, children, value, onDrop, bookingInfo = [] }) => {
    const { calendarData, calendarConfig } = useScheduleCalendarContext();
    const lessonDuration = GradeRangeToLessonDuration[calendarConfig.educationLevelGroup];

    const isTimeGutter = resource === undefined; // null for regular timeslots // todo: more reliable way?

    const viewMode = useWatch({
        name: 'viewMode',
        defaultValue: null
    });

    const [{ highlighted, item }, dropRef] = useDrop(
        () => ({
            accept: DragItem.SCHEDULE_CARD,
            drop: (event: ScheduleCalendarEvent) => {
                onDrop(value, event);
                return { event, target: DragAndDropZone.TIMESLOT };
            },
            collect: (monitor) => ({
                highlighted: monitor.isOver(),
                item: monitor.getItem()
            })
        }),
        [value]
    );

    const timeSlotFrom = new Date(value).getTime();
    const timeSlotTo = new Date(value).getTime() + lessonDuration * 1000 * 60;

    const bookedUserSlots = bookingInfo
        .map((timeSlot) => {
            const date = new Date(timeSlot.date);
            const bookedSlots = timeSlot.bookedTime.map((timeInterval) => {
                const from = DateTimeUtils.getFullDate(date, timeInterval.from);
                const to = DateTimeUtils.getFullDate(date, timeInterval.to);
                return { from, to };
            });

            return bookedSlots;
        })
        .flat();

    const isTimeBooked = bookedUserSlots.some((userTimeSlot) => {
        return (
            (userTimeSlot.from.getTime() >= timeSlotFrom && userTimeSlot.from.getTime() <= timeSlotTo) ||
            (userTimeSlot.to.getTime() >= timeSlotFrom && userTimeSlot.to.getTime() <= timeSlotTo)
        );
    });

    let isBreakTime = false;
    let isWorkTime = true;

    if (calendarData.educationCenter?.breakStart && calendarData.educationCenter?.breakEnd) {
        const breakTimeFrom = DateTimeUtils.convertTimeToDate(
            calendarData.educationCenter?.breakStart,
            new Date(value)
        );
        const breakTimeTo = DateTimeUtils.convertTimeToDate(calendarData.educationCenter?.breakEnd, new Date(value));

        isBreakTime = timeSlotFrom >= breakTimeFrom.getTime() && timeSlotTo <= breakTimeTo.getTime();
    }

    if (calendarData.educationCenter?.workStart && calendarData.educationCenter?.workEnd) {
        const workTimeFrom = DateTimeUtils.convertTimeToDate(calendarData.educationCenter?.workStart, new Date(value));
        const workTimeTo = DateTimeUtils.convertTimeToDate(calendarData.educationCenter?.workEnd, new Date(value));

        isWorkTime = timeSlotFrom >= workTimeFrom.getTime() && timeSlotTo <= workTimeTo.getTime();
    }

    const isDropAllowed = useMemo(
        () => !isTimeGutter && !isTimeBooked && !isBreakTime && isWorkTime,
        [isTimeGutter, isTimeBooked, isBreakTime, isWorkTime]
    );

    return (
        <StyledTimeSlot
            ref={isDropAllowed ? dropRef : null}
            title={item?.title || ''}
            isTimeBooked={isTimeBooked}
            isTimeGutter={isTimeGutter}
            isBreakTime={isBreakTime}
            isWorkTime={isWorkTime}
            highlighted={highlighted}
            isViewMode={viewMode}>
            {children}
        </StyledTimeSlot>
    );
};
