import { FC } from 'react';
import { SimpleForm } from 'react-admin';
import { Container, Box, Stack } from '@mui/material';
import { palette } from 'src/Theme';
import { ScheduleSidebar } from './ScheduleSidebar/ScheduleSidebar';
import { TabbedScheduleCalendar } from './ScheduleCalendar/TabbedScheduleCalendar';
import { useScheduleCalendarContext } from '../common/context/CalendarContext';

export const ScheduleListView: FC = () => {
    const { calendarConfig } = useScheduleCalendarContext();

    return (
        <Box width={'100vw'}>
            <SimpleForm toolbar={false} sx={{ padding: 0 }}>
                <Stack direction={'row'} sx={{ backgroundColor: 'white' }}>
                    {!calendarConfig.viewMode && (
                        <Container sx={{ padding: '16px 8px', width: '280px' }}>
                            <ScheduleSidebar />
                        </Container>
                    )}
                    <Container
                        sx={{
                            backgroundColor: palette.primary.grayLight,
                            margin: 0,
                            padding: '35px 16px',
                            '@media (min-width: 1200px)': {
                                maxWidth: 'fit-content'
                            }
                        }}>
                        <TabbedScheduleCalendar />
                    </Container>
                </Stack>
            </SimpleForm>
        </Box>
    );
};
