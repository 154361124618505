export enum EmployeeFormFields {
    ID = 'id',
    VERSION = 'version',

    BIRTH_CERTIFICATE_ID = 'birthCertificateId',
    FIRST_NAME = 'name',
    LAST_NAME = 'surname',
    PATRONYMIC = 'patronymic',
    BIRTH_DATE = 'birthDate',
    GENDER = 'gender',
    REGISTRATION = 'registrationAddress',
    EMPLOYEES = 'employees',
    CREATED = 'created',
    EMPLOYEES_CENTERS = 'employeeCenters',

    EMPLOYEES_DATE_OF_STARTING = 'dateOfStarting',
    EMPLOYEES_DATE_OF_ENDING = 'dateOfEnding',

    EMPLOYEE_DETAILS = 'employeeDetails',

    TAX_NUMBER = 'taxNumber',
    EMAIL = 'email',
    PHONE = 'phone',
    ACTIVE = 'active'
}
