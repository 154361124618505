const today = new Date();
const tuesday = new Date(today.setDate(today.getDate() - today.getDay() + 2)).toISOString();
const thursday = new Date(today.setDate(today.getDate() - today.getDay() + 4)).toISOString();
const friday = new Date(today.setDate(today.getDate() - today.getDay() + 5)).toISOString();

export const ChildrenMock = [
    {
        id: 1,
        name: 'Іван',
        surname: 'Валовий',
        lessonCount: 1,
        bookingInfo: [
            {
                date: tuesday,
                bookedTime: [
                    {
                        from: {
                            hour: 9,
                            minute: 30,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 10,
                            minute: 30,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        name: 'Андрій',
        surname: 'Чорновий',
        lessonCount: 1,
        bookingInfo: [
            {
                date: thursday,
                bookedTime: [
                    {
                        from: {
                            hour: 11,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 12,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        name: 'Юрій',
        surname: 'Володаренко',
        lessonCount: 1,
        bookingInfo: [
            {
                date: tuesday,
                bookedTime: [
                    {
                        from: {
                            hour: 14,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 15,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 24,
        name: 'Емілія',
        surname: 'Петренко',
        lessonCount: 1,
        bookingInfo: [
            {
                date: friday,
                bookedTime: [
                    {
                        from: {
                            hour: 16,
                            minute: 30,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 17,
                            minute: 30,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 900,
        name: 'Марія',
        surname: 'Павлова',
        lessonCount: 1,
        bookingInfo: [
            {
                date: friday,
                bookedTime: [
                    {
                        from: {
                            hour: 13,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 14,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    }
];
