import { ScheduleCalendarEvent } from 'src/components/Schedule/List/ScheduleCalendar/ScheduleCalendar.types';
import { EducationLevel } from 'src/constants';

export const CALENDAR_EVENTS_MOCK: ScheduleCalendarEvent[] = [
    {
        start: new Date(new Date().setHours(10, 0, 0, 0)),
        end: new Date(new Date().setHours(11, 0, 0, 0)),
        title: 'Інформатика',
        educationLevels: [EducationLevel.YEARS4, EducationLevel.YEARS5, EducationLevel.GRADE3],
        id: crypto.randomUUID()
    },
    {
        start: new Date(new Date().setHours(11, 0, 0, 0)),
        end: new Date(new Date().setHours(12, 0, 0, 0)),
        title: 'Фізкультура',
        educationLevels: [EducationLevel.YEARS6, EducationLevel.GRADE1],
        id: crypto.randomUUID()
    },
    {
        start: new Date(new Date().setHours(17, 0, 0, 0)),
        end: new Date(new Date().setHours(18, 0, 0, 0)),
        title: 'Іноземна мова (англійська)',
        educationLevels: [EducationLevel.GRADE5, EducationLevel.GRADE7],
        id: crypto.randomUUID()
    }
];
