import React from 'react';
import { DeleteWithConfirmButton, Toolbar } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/material';

import { shsToolbarStyles } from 'src/styles';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { ShsButton, ShsSaveButton, WithRole } from 'src/components/common';
import { UserRole } from 'src/auth/types';

/**
 * @property { boolean } isOpen - Controls modal visibility
 * @property { boolean } isEdit - Indicates if in edit mode (true) or addition mode (false)
 * @property { Function } onClose - Callback for closing the modal
 * @property { Function } onCancel - Callback for confirming cancellation
 */
export type ModalProps = {
    isOpen: boolean;
    isEdit?: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
};

/**
 * Properties for the ShsFormToolbar component
 * @property { string } [submitLabel] - Label for the save button
 * @property { string } [cancelLabel] - Label for the cancel button
 * @property { React.FC<ModalProps> } [cancelDialog] - Cancel dialog component
 * @property { boolean } [enableDeleteButton] - If true, enables delete button
 * @property { boolean } [enableCancelButton] - If true, enables cancel button
 * @property { boolean } [enableSaveButton] - If true, enables save button
 * @property { string } [deleteLabel] - Label for the delete button
 * @property { React.ReactNode } [deleteConfirmContent] - Content to display in delete confirmation
 * @property { UserRole[] } [enableDeleteForRoles] - Roles, who allowed to delete
 * @property { string } [className] - Custom class name for the toolbar
 * @property { SxProps } [sx] - Custom styles for the toolbar
 * @property { boolean } [shouldCheckIfDirty] - If true, checks if the form is dirty before canceling
 * @property { Function } [handleSave] - Callback for saving the form
 * @property { Function } handleCancel - Callback for canceling the form
 */
export interface ShsFormToolbarProps {
    submitLabel?: string;
    cancelLabel?: string;
    cancelDialog?: React.FC<ModalProps>;
    enableDeleteButton?: boolean;
    enableCancelButton?: boolean;
    enableSaveButton?: boolean;
    deleteLabel?: string;
    deleteConfirmContent?: React.ReactNode;
    enableDeleteForRoles?: UserRole[];
    className?: string;
    sx?: SxProps;
    shouldCheckIfDirty?: boolean;
    handleSave?: () => void;
    handleCancel: () => void;
}

/**
 * ShsFormToolbar component for handling form actions
 */
const ShsFormToolbar: React.FC<ShsFormToolbarProps> = ({
    submitLabel = 'Зберегти',
    cancelLabel = 'Скасувати',
    cancelDialog: CancelDialog,
    enableDeleteButton = false,
    enableCancelButton = true,
    enableSaveButton = true,
    deleteLabel = 'Видалити',
    deleteConfirmContent,
    enableDeleteForRoles = [],
    className,
    sx,
    shouldCheckIfDirty = true,
    handleCancel,
    handleSave = () => {}
}) => {
    const { isDirty } = useFormState();
    const [openCancel, setOpenCancel] = React.useState(false);

    /**
     * Handler for the cancel button click
     * If CancelDialog exists and the form is dirty (or if dirty check is disabled), it opens the cancel confirmation dialog
     * Otherwise, it calls the handleCancel function
     */
    const handleCancelClick = () =>
        CancelDialog && (!shouldCheckIfDirty || isDirty) ? setOpenCancel(true) : handleCancel();
    /**
     * Handler for closing the cancel confirmation dialog
     * Resets the open dialog state
     */
    const handleCancelDialogClose = () => setOpenCancel(false);

    return (
        <Toolbar sx={shsToolbarStyles} className="add-child-toolbar">
            <Box sx={{ flexGrow: 1 }}>
                <ShsSaveButton disabled={!enableSaveButton} onClick={handleSave} label={submitLabel} sx={{ mr: 2 }} />
                {enableCancelButton && (
                    <ShsButton label={cancelLabel} styleType="outline" onClick={handleCancelClick} />
                )}

                {CancelDialog && (
                    <CancelDialog isOpen={openCancel} onConfirm={handleCancel} onClose={handleCancelDialogClose} />
                )}
            </Box>
            <WithRole roles={enableDeleteForRoles}>
                {enableDeleteButton && (
                    <DeleteWithConfirmButton
                        className={className}
                        sx={sx}
                        label={deleteLabel}
                        icon={<DeleteIcon style={{ fill: '#F23030' }} />}
                        confirmTitle=""
                        confirmColor="warning"
                        confirmContent={deleteConfirmContent}
                    />
                )}
            </WithRole>
        </Toolbar>
    );
};

export default ShsFormToolbar;
