import type { UpdateParams, GetListParams } from 'react-admin';
import axiosApiInstance, { postRequestCacheConfig } from '../../axiosConfig';
import { Resource } from 'src/constants/resources';
import { HospitalDepartment } from 'src/components/Employees/types';

export default {
    getList: async (params: GetListParams) => {
        let { keyword, ...filters } = params.filter;
        filters = Object.keys(filters).length ? filters : undefined;

        const pageRequest = {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: params.sort && { properties: [params.sort.field], direction: params.sort.order },
            keyword,
            filters,
            _timestamp: Date.now()
        };

        const response = await axiosApiInstance.post(Resource.CENTERS, pageRequest, {
            ...postRequestCacheConfig,
            headers: {
                ...postRequestCacheConfig.headers,
                'Cache-Control': 'no-cache'
            }
        });

        return {
            data: response.data.content,
            total: response.data.totalElements
        };
    },

    update: (params: UpdateParams) => {
        const hospitalDepartments = params.data.hospitalDepartments.map((x: HospitalDepartment) => ({
            id: x.id,
            version: x.id ? x.version : undefined,
            name: x.departmentName
        }));

        return axiosApiInstance
            .put(`${Resource.CENTERS}/update`, { ...params.data, hospitalDepartments: hospitalDepartments })
            .then((response) => response);
    }
};
