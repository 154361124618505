import { Gender } from 'src/constants/common';
import { EducationLevelGroup } from '../school';

export const ChildGenderList = [
    { id: Gender.FEMALE, name: 'Дівчинка' },
    { id: Gender.MALE, name: 'Хлопчик' },
    { id: Gender.OTHER, name: 'Інше' }
];

export enum EducationAction {
    REMOTE = 'REMOTE',
    EXTERNAL = 'EXTERNAL',
    PEDAGOGICAL = 'PEDAGOGICAL',
    PATRONAGE = 'PATRONAGE'
}

export const AdditionalEducationActionList = [
    { id: EducationAction.REMOTE, name: 'Дистанційна' },
    { id: EducationAction.EXTERNAL, name: 'Екстернатна' },
    { id: EducationAction.PEDAGOGICAL, name: 'Педагогічна' },
    { id: EducationAction.PATRONAGE, name: 'Патронажна' }
];

export enum EducationLevel {
    YEARS4 = 'YEARS4',
    YEARS5 = 'YEARS5',
    YEARS6 = 'YEARS6',
    GRADE1 = 'GRADE1',
    GRADE2 = 'GRADE2',
    GRADE3 = 'GRADE3',
    GRADE4 = 'GRADE4',
    GRADE5 = 'GRADE5',
    GRADE6 = 'GRADE6',
    GRADE7 = 'GRADE7',
    GRADE8 = 'GRADE8',
    GRADE9 = 'GRADE9',
    GRADE10 = 'GRADE10',
    GRADE11 = 'GRADE11'
}

export const EducationLevelList = [
    { id: EducationLevel.YEARS4, name: 'Дошкільна - 4 роки' },
    { id: EducationLevel.YEARS5, name: 'Дошкільна - 5 років' },
    { id: EducationLevel.YEARS6, name: 'Дошкільна - 6 років' },
    { id: EducationLevel.GRADE1, name: '1 клас' },
    { id: EducationLevel.GRADE2, name: '2 клас' },
    { id: EducationLevel.GRADE3, name: '3 клас' },
    { id: EducationLevel.GRADE4, name: '4 клас' },
    { id: EducationLevel.GRADE5, name: '5 клас' },
    { id: EducationLevel.GRADE6, name: '6 клас' },
    { id: EducationLevel.GRADE7, name: '7 клас' },
    { id: EducationLevel.GRADE8, name: '8 клас' },
    { id: EducationLevel.GRADE9, name: '9 клас' },
    { id: EducationLevel.GRADE10, name: '10 клас' },
    { id: EducationLevel.GRADE11, name: '11 клас' }
];

export const EducationLevelGroupList: Record<EducationLevelGroup, { id: EducationLevel; name: string }[]> = {
    [EducationLevelGroup.HIGH_SCHOOL]: [
        { id: EducationLevel.GRADE5, name: '5 клас' },
        { id: EducationLevel.GRADE6, name: '6 клас' },
        { id: EducationLevel.GRADE7, name: '7 клас' },
        { id: EducationLevel.GRADE8, name: '8 клас' },
        { id: EducationLevel.GRADE9, name: '9 клас' },
        { id: EducationLevel.GRADE10, name: '10 клас' },
        { id: EducationLevel.GRADE11, name: '11 клас' }
    ],
    [EducationLevelGroup.JUNIOR_SCHOOL]: [
        { id: EducationLevel.GRADE1, name: '1 клас' },
        { id: EducationLevel.GRADE2, name: '2 клас' },
        { id: EducationLevel.GRADE3, name: '3 клас' },
        { id: EducationLevel.GRADE4, name: '4 клас' }
    ],
    [EducationLevelGroup.PRESCHOOL]: [
        { id: EducationLevel.YEARS4, name: 'Дошкільна - 4 роки' },
        { id: EducationLevel.YEARS5, name: 'Дошкільна - 5 років' },
        { id: EducationLevel.YEARS6, name: 'Дошкільна - 6 років' }
    ]
};

export enum PreschoolAgeLevel {
    AGE3 = 'AGE3',
    AGE4 = 'AGE4',
    AGE5 = 'AGE5',
    AGE6 = 'AGE6'
}

export const PreschoolAgeLevelList = [
    { id: PreschoolAgeLevel.AGE3, name: '3' },
    { id: PreschoolAgeLevel.AGE4, name: '4' },
    { id: PreschoolAgeLevel.AGE5, name: '5' },
    { id: PreschoolAgeLevel.AGE6, name: '6' }
];

export enum SpecialEducationRequirements {
    ALTERNATIVE_COMMUNICATION = 'ALTERNATIVE_COMMUNICATION',
    SIGN_LANGUAGE = 'SIGN_LANGUAGE',
    SPEECH_THERAPIST = 'SPEECH_THERAPIST',
    PSYCHOLOGIST = 'PSYCHOLOGIST',
    BRAILLE = 'BRAILLE'
}

export const SpecialEducationRequirementsList = [
    { id: SpecialEducationRequirements.ALTERNATIVE_COMMUNICATION, name: 'Альтернативні засоби комунікації' },
    { id: SpecialEducationRequirements.SIGN_LANGUAGE, name: 'Жестова мова' },
    { id: SpecialEducationRequirements.SPEECH_THERAPIST, name: 'Логопед-дефектолог' },
    { id: SpecialEducationRequirements.PSYCHOLOGIST, name: 'Психолог' },
    { id: SpecialEducationRequirements.BRAILLE, name: 'Шрифт Брайля' }
];

export enum DisabilityGroup {
    GROUP1 = 'GROUP1',
    GROUP2 = 'GROUP2',
    GROUP3 = 'GROUP3'
}

export const DisabilityGroupList = [
    { id: DisabilityGroup.GROUP1, name: 'І група' },
    { id: DisabilityGroup.GROUP2, name: 'ІІ група' },
    { id: DisabilityGroup.GROUP3, name: 'ІІІ група' }
];

export enum DisabilityReason {
    DISEASE = 'DISEASE',
    INJURIES = 'INJURIES',
    CONGENITAL = 'CONGENITAL'
}

export const DisabilityReasonList = [
    { id: DisabilityReason.DISEASE, name: 'Захворювання' },
    { id: DisabilityReason.INJURIES, name: 'Травми їх наслідки' },
    { id: DisabilityReason.CONGENITAL, name: 'Вроджені порушення' }
];

export enum ChildFormFields {
    ID = 'id',
    VERSION = 'version',

    BIRTH_CERTIFICATE_ID = 'birthCertificateId',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    PATRONYMIC = 'patronymic',
    BIRTH_DATE = 'birthDate',
    GENDER = 'gender',
    RESIDENCE = 'actualAddress',
    REGISTRATION = 'registrationAddress',

    EDUCATIONAL_INSTITUTION_REFERENCE = 'educationInstitutionUrl',
    EDUCATIONAL_INSTITUTION_PHONE = 'educationInstitutionPhone',
    EDUCATIONAL_INSTITUTION_EMAIL = 'educationInstitutionEmail',
    EDUCATIONAL_INSTITUTION_ADDITIONAL_ACTION = 'additionalActionRequired',

    VISIT = 'visit',
    VISITORS = 'visitParents',
    PARENT = 'parent',
    VISITOR_TAX_NUMBER = 'taxNumber',
    VISITOR_FIRST_NAME = 'name',
    VISITOR_LAST_NAME = 'surname',
    VISITOR_PATRONYMIC = 'patronymic',
    VISITOR_EMAIL = 'email',
    VISITOR_PHONE = 'phone',
    VISITOR_ROLE = 'role',
    VISITOR_ACTIVE = 'active',

    EDUCATIONAL_LEVEL = 'educationLevel',
    PRESCHOOL_AGE_LEVEL = 'preschoolAgeLevel',
    EDUCATION_CENTER = 'educationCenterId',
    EDUCATION_CENTER_DATA = 'educationCenter',
    HOSPITAL_DEPARTMENT_DATA = 'hospitalDepartment',
    HOSPITAL_DEPARTMENT = 'hospitalDepartmentId',
    EDUCATIONAL_AGREEMENT_DATE = 'servicesAgreementDate',
    EDUCATIONAL_SERVICE_END = 'servicesEndDate',
    EDUCATIONAL_SPECIAL_REQUIREMENTS = 'specialRequirements',
    DISABILITY = 'disabilityPresent',
    DISABILITY_GROUP = 'disabilityGroup',
    DISABILITY_REASON = 'disabilityReason',
    ORPHAN = 'orphan',
    DISPLACED = 'internallyDisplaced',
    WAR_VICTIM = 'warVictim',
    COMPLICATED_LIFE_CIRCUMSTANCES = 'complicatedLifeCircumstances',
    OTHER_SOCIAL_STATUS = 'otherSocialStatus',
    MEDIA_RECORDING_AGREEMENT = 'mediaRecordingAgreement',
    MEDIA_RECORDING_LINK = 'mediaRecordingLink',
    CHILD_INFO = 'info'
}

export const ChildFormDefaults = {
    // [ChildFormFields.ID]: 0,
    [ChildFormFields.VERSION]: 0,
    [ChildFormFields.BIRTH_CERTIFICATE_ID]: '',
    [ChildFormFields.FIRST_NAME]: '',
    [ChildFormFields.LAST_NAME]: '',
    [ChildFormFields.PATRONYMIC]: '',
    [ChildFormFields.BIRTH_DATE]: '',
    [ChildFormFields.GENDER]: Gender.FEMALE,
    [ChildFormFields.VISIT]: {
        [ChildFormFields.RESIDENCE]: '',
        [ChildFormFields.REGISTRATION]: '',
        [ChildFormFields.EDUCATIONAL_INSTITUTION_REFERENCE]: '',
        [ChildFormFields.EDUCATIONAL_INSTITUTION_PHONE]: '',
        [ChildFormFields.EDUCATIONAL_INSTITUTION_EMAIL]: '',
        [ChildFormFields.EDUCATIONAL_INSTITUTION_ADDITIONAL_ACTION]: {
            enabled: false,
            action: null
        },
        [ChildFormFields.VISITORS]: [
            {
                [ChildFormFields.PARENT]: {
                    [ChildFormFields.VISITOR_TAX_NUMBER]: '',
                    [ChildFormFields.VISITOR_FIRST_NAME]: '',
                    [ChildFormFields.VISITOR_LAST_NAME]: '',
                    [ChildFormFields.VISITOR_PATRONYMIC]: '',
                    [ChildFormFields.VISITOR_EMAIL]: '',
                    [ChildFormFields.VISITOR_PHONE]: ''
                },
                [ChildFormFields.VISITOR_ROLE]: '',
                [ChildFormFields.VISITOR_ACTIVE]: false
            }
        ],
        [ChildFormFields.EDUCATIONAL_LEVEL]: null,
        [ChildFormFields.PRESCHOOL_AGE_LEVEL]: null,
        [ChildFormFields.EDUCATION_CENTER]: null,
        [ChildFormFields.HOSPITAL_DEPARTMENT]: null,
        [ChildFormFields.EDUCATIONAL_AGREEMENT_DATE]: '',
        [ChildFormFields.EDUCATIONAL_SERVICE_END]: null,
        [ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS]: {
            enabled: false,
            parameters: []
        },
        [ChildFormFields.DISABILITY]: false,
        [ChildFormFields.DISABILITY_GROUP]: null,
        [ChildFormFields.DISABILITY_REASON]: null,
        [ChildFormFields.ORPHAN]: false,
        [ChildFormFields.DISPLACED]: false,
        [ChildFormFields.WAR_VICTIM]: false,
        [ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES]: false,
        [ChildFormFields.OTHER_SOCIAL_STATUS]: '',
        [ChildFormFields.MEDIA_RECORDING_AGREEMENT]: false,
        [ChildFormFields.MEDIA_RECORDING_LINK]: null,
        [ChildFormFields.CHILD_INFO]: ''
    }
};

export const SocialStatusLabels = {
    [ChildFormFields.ORPHAN]: 'Дитина сирота чи дитина позбавлена батьківського піклування',
    [ChildFormFields.DISPLACED]: 'Дитина ВПО',
    [ChildFormFields.WAR_VICTIM]: 'Має статус постаждалих в наслідок воєнних дій та збройних конфліктів',
    [ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES]: 'Складні життєві обставини'
};
