import React, { useEffect, useState } from 'react';
import { ArrayInput, FormDataConsumer, SimpleFormIterator } from 'react-admin';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { disableFirstRemoveStyle, formIteratorStyles } from 'src/styles';
import { ChildFormFields } from 'src/constants';
import { ShsButton } from 'src/components/common';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash-can.svg';
import VisitorData from './VisitorData';
import { useWatch } from 'react-hook-form';
import { Theme } from 'src/Theme';

const VisitorsList: React.FC = () => {
    const visitors = useWatch({
        name: `${ChildFormFields.VISIT}.${ChildFormFields.VISITORS}`,
        defaultValue: null
    });

    const [disableAdd, setDisableAdd] = useState<boolean>(true);

    useEffect(() => {
        setDisableAdd(
            visitors?.some(
                (item: any) =>
                    !item ||
                    !item.parent.surname ||
                    !item.parent.name ||
                    !item.parent.taxNumber ||
                    !item.parent.phone ||
                    !item.role
            )
        );
    }, [visitors, setDisableAdd]);

    return (
        <ArrayInput source={`${ChildFormFields.VISIT}.${ChildFormFields.VISITORS}`} label="">
            <SimpleFormIterator
                inline
                getItemLabel={(index) => (
                    <Typography
                        minWidth={'100%'}
                        variant="h5"
                        component="span"
                        mb={1}
                        mt={1}
                        fontWeight="600"
                        color={Theme.palette.primary.midnightBlue}>
                        {`Законний представник дитини - ${index + 1}`}
                    </Typography>
                )}
                className="with-bottom-border"
                disableAdd={visitors?.length >= 10}
                addButton={
                    <Box sx={{ mt: 1 }}>
                        <ShsButton
                            disabled={disableAdd}
                            styleType="outline-no-border"
                            sx={{ paddingLeft: '0 !important' }}
                            label="Додати представника"
                            icon={<AddCircleOutlineIcon sx={{ fontSize: '24px !important' }} />}
                        />
                    </Box>
                }
                removeButton={<ShsButton styleType="text-danger" sx={{ p: 0, mb: 1 }} label="" icon={<DeleteIcon />} />}
                disableClear
                disableReordering
                sx={{
                    ...disableFirstRemoveStyle,
                    ...formIteratorStyles,
                    '& .RaSimpleFormIterator-line': {
                        flexWrap: 'wrap',
                        flexDirection: 'row'
                    },
                    '& .RaSimpleFormIterator-inline': {
                        maxWidth: '85%'
                    },
                    '& .RaSimpleFormIterator-action': {
                        marginTop: '70px'
                    }
                }}>
                <FormDataConsumer sx={{ maxWidth: '85%' }}>
                    {({ getSource }) =>
                        getSource ? (
                            <VisitorData getSource={getSource} hasActive={visitors?.find((x: any) => x.active)} />
                        ) : null
                    }
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default VisitorsList;
