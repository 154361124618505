import { FC } from 'react';
import { useQuery } from 'react-query';
import { useDataProvider } from 'react-admin';

import { EducationLevelGroup, EducationStage, Resource } from 'src/constants';
import { SubjectGroupList } from 'src/api/REST/subjects/types';
import EducationStageSubjectsList from './EducationLevelSubjectsList';

const EducationLevelSubjects: FC<{
    educationLevelGroup: EducationLevelGroup;
    reloadKey: boolean;
    refresh: () => void;
}> = ({ educationLevelGroup, reloadKey, refresh }) => {
    const dataProvider = useDataProvider();
    const { data } = useQuery<SubjectGroupList>([educationLevelGroup, reloadKey], () =>
        dataProvider.getGroupList(Resource.SUBJECTS, {
            meta: { educationLevelGroup }
        })
    );

    if (!data || !Object.entries(data).length) return null;

    return (
        <>
            {Object.entries(data).map(([educationStage, subjects]) => (
                <EducationStageSubjectsList
                    educationStage={educationStage as EducationStage}
                    data={subjects}
                    key={educationStage}
                    refresh={refresh}
                />
            ))}
        </>
    );
};

export default EducationLevelSubjects;
