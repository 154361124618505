import React from 'react';
import cn from 'classnames';
import { TextInputProps, InputHelperText, required as requiredValidate, useInput } from 'react-admin';
import { MuiTelInput } from 'mui-tel-input';
import type { CountryCode } from 'libphonenumber-js';
import { styled } from '@mui/system';
import { palette } from '../../../../Theme';

const styles = {
    '.MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: `${palette.primary.main}`
        },
        '&:focus fieldset': {
            border: `3px solid #75CACB`
        }
    },
    '&.MuiFormControl-root.invalid .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d32f2f'
    },
    '&.MuiFormControl-root.invalid .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2FA9A6 !important'
    },
    '& .MuiInputBase-root': {
        background: '#ffffff',
        padding: 0
    },
    '& .MuiInputBase-root .MuiButtonBase-root': {
        width: '65px',
        height: '45px',
        borderRadius: 0,
        borderRight: `1px solid ${palette.primary.inputBorder}`
    },
    '& .MuiInputBase-input': {
        padding: '11px 14px 11px 0'
    },
    '& .error': {
        color: '#d32f2f',
        fontSize: '0.75rem',
        padding: 0
    }
};

const StyledPhoneInput = styled(MuiTelInput)(styles);

const StyledErrors = styled('p')({
    color: '#d32f2f',
    fontSize: '0.75rem',
    padding: 0,
    margin: '-5px 14px 0 2px'
});

interface ShsPhoneInputProps extends TextInputProps {
    label?: string;
    required?: boolean;
    labelPosition?: 'top' | 'left';
}

const ShsPhoneInput: React.FC<ShsPhoneInputProps> = ({ label, required, source, labelPosition = 'top' }, props) => {
    const [value, setValue] = React.useState('');
    const validateFn = [...(required ? [requiredValidate("Обов'язково для заповнення")] : [])];
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput({ source, isRequired: required, validate: validateFn });

    React.useEffect(() => {
        setValue(field.value || '');
    }, []);

    const isRequired = () => (required ? '*' : '');
    const defaultCountry = 'UA' as CountryCode;

    const handleChange = (newValue: string) => {
        const value = (newValue || '').replace(/ /g, '');
        setValue(value);
        field.onChange(value);
    };

    const renderHelperText = (isTouched || isSubmitted) && invalid;

    return (
        <div className={cn('text_field__container', label ? labelPosition : '')}>
            <label className="text_field__label">
                {label || ''}
                {isRequired()}
            </label>
            <StyledPhoneInput
                {...field}
                id={source}
                value={value}
                onChange={handleChange}
                onlyCountries={[defaultCountry]}
                defaultCountry={defaultCountry}
                placeholder="(000) 000-00-00"
                lang={defaultCountry}
                className={invalid ? 'invalid' : ''}
                {...props}
            />
            {renderHelperText ? (
                <StyledErrors>
                    <InputHelperText touched={isTouched || isSubmitted} error={error?.message} />
                </StyledErrors>
            ) : null}
        </div>
    );
};

export default ShsPhoneInput;
