const today = new Date();
const monday = new Date(today.setDate(today.getDate() - today.getDay() + 1)).toISOString();
const wednesday = new Date(today.setDate(today.getDate() - today.getDay() + 3)).toISOString();
const thursday = new Date(today.setDate(today.getDate() - today.getDay() + 4)).toISOString();

export const EmployeesMock = [
    {
        id: 67,
        userId: 67,
        name: 'Дмитро',
        surname: 'Скоробогатий',
        scheduledServicesCount: 3,
        bookingInfo: [
            {
                date: monday,
                bookedTime: [
                    {
                        from: {
                            hour: 11,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 12,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            },
            {
                date: wednesday,
                bookedTime: [
                    {
                        from: {
                            hour: 8,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 9,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            },
            {
                date: thursday,
                bookedTime: [
                    {
                        from: {
                            hour: 11,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 13,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 70,
        userId: 70,
        name: 'Тетяна',
        surname: 'Івченко',
        scheduledServicesCount: 3,
        bookingInfo: [
            {
                date: monday,
                bookedTime: [
                    {
                        from: {
                            hour: 11,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 12,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 97,
        userId: 97,
        name: 'Григорій',
        surname: 'Григоренко',
        scheduledServicesCount: 3,
        bookingInfo: [
            {
                date: wednesday,
                bookedTime: [
                    {
                        from: {
                            hour: 12,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 13,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            },
            {
                date: thursday,
                bookedTime: [
                    {
                        from: {
                            hour: 14,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        to: {
                            hour: 15,
                            minute: 0,
                            second: 0,
                            nano: 0
                        }
                    }
                ]
            }
        ]
    }
];
