import React from 'react';
import { Container, Typography } from '@mui/material';
import { useCreatePath, TabbedForm, Create, useRedirect, FormGroupContextProvider } from 'react-admin';

import { editContainerStyles } from 'src/styles';
import { ReactComponent as ChevronRight } from 'src/assets/chevron-right.svg';
import { ShsReturnButton } from 'src/components/common';

import {
    CenterWorkingHours,
    GeneralCenterInfo,
    CenterLessonsDuration,
    CenterDepartment,
    CenterLessonsPlace
} from 'src/components/EducationalCenters/FieldGroups';
import { CenterFormFields, centerDefaultValues } from 'src/constants/centers';
import { getSchoolLessons } from 'src/utils/centers';
import { palette, Theme } from 'src/Theme';
import { TabLabel } from '../Employees/components/TabLabel';
import tabGroups from './const/tab-groups';
import useTabbedFormNavigation from '../common/ShsTabbedFormToolbar/useTabIndex';
import { ShsTabbedFormToolbar } from '../common/ShsTabbedFormToolbar';

const tabbedFormStyles = {
    'a.form-tab.MuiTab-labelIcon': {
        flex: '0 1 auto'
    },
    '.MuiTabs-indicator': {
        display: 'none'
    },
    '.Mui-selected.Mui-disabled': {
        color: Theme.palette.primary.main
    }
};

const tabStyles = {
    padding: 0,
    '&.MuiTab-labelIcon': {
        flexFlow: 'row-reverse'
    },
    '.MuiTab-iconWrapper': {
        margin: '0 32px'
    }
};

export const EducationalCenterAdd: React.FC = () => {
    const redirect = useRedirect();
    const { tabIndex } = useTabbedFormNavigation();
    const createPath = useCreatePath();

    const onSuccess = () => {
        redirect(createPath({ resource: 'centers', type: 'list' }));
    };

    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton to={createPath({ resource: 'centers', type: 'list' })} label="Назад до освітніх центрів" />
            <Create mutationOptions={{ onSuccess }}>
                <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                    Додати освітній центр
                </Typography>
                <TabbedForm
                    sx={tabbedFormStyles}
                    mode="onBlur"
                    toolbar={<ShsTabbedFormToolbar tabGroups={tabGroups} saveTitle="Додати центр" />}
                    defaultValues={centerDefaultValues}>
                    <TabbedForm.Tab
                        label={<TabLabel label="Загальна інформація" index={0} />}
                        icon={<ChevronRight stroke={palette.primary.gray} />}
                        sx={tabStyles}
                        disabled={tabIndex === 0}>
                        <FormGroupContextProvider name={tabGroups[0]}>
                            <GeneralCenterInfo />
                            <CenterWorkingHours />
                            <CenterLessonsDuration
                                educationalLevel="Дошкільна освіта"
                                lessonDurationSource={`${CenterFormFields.LESSONS_DURATION}.${CenterFormFields.BEFORE_SCHOOL_EDUCATION}.${CenterFormFields.LESSON_DURATION}`}
                                schoolLessons={getSchoolLessons(CenterFormFields.BEFORE_SCHOOL_EDUCATION)}
                                isTitleShown
                            />
                            <CenterLessonsDuration
                                educationalLevel="1-4 класи"
                                lessonDurationSource={`${CenterFormFields.LESSONS_DURATION}.${CenterFormFields.FIRST_FOURTH_GRADE}.${CenterFormFields.LESSON_DURATION}`}
                                schoolLessons={getSchoolLessons(CenterFormFields.FIRST_FOURTH_GRADE)}
                            />
                            <CenterLessonsDuration
                                educationalLevel="5-11 класи"
                                lessonDurationSource={`${CenterFormFields.LESSONS_DURATION}.${CenterFormFields.FIFTH_TENTH_GRADE}.${CenterFormFields.LESSON_DURATION}`}
                                schoolLessons={getSchoolLessons(CenterFormFields.FIFTH_TENTH_GRADE)}
                            />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label={<TabLabel label="Відділення" index={1} />}
                        icon={<ChevronRight stroke={palette.primary.gray} />}
                        sx={tabStyles}
                        disabled={tabIndex < 1}>
                        <FormGroupContextProvider name={tabGroups[1]}>
                            <CenterDepartment />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label={<TabLabel label="Місця проведення уроків" index={2} />}
                        sx={tabStyles}
                        disabled={tabIndex < 2}>
                        <FormGroupContextProvider name={tabGroups[2]}>
                            <CenterLessonsPlace />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                </TabbedForm>
            </Create>
        </Container>
    );
};
