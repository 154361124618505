import { GetListParams } from 'react-admin';
import { CALENDAR_EVENTS_MOCK } from 'src/api/mocks/ScheduleEventsMock';
import { ScheduleCalendarEvent } from 'src/components/Schedule/List/ScheduleCalendar/ScheduleCalendar.types';
import { GradeRangeToLessonDuration } from 'src/components/Schedule/common/const/Calendar.const';
import { EducationLevelGroup, EducationLevelGroupList } from 'src/constants';

const getGroupList = async (params: GetListParams): Promise<ScheduleCalendarEvent[]> => {
    const { educationLevelGroup, startDate, endDate } = params.meta;

    // const response = await axiosApiInstance.get(Resource.SCHEDULE, {
    //     params: {
    //         targetGradeRange: EducationLevelGradeRange[educationLevelGroup],
    //         startDate,
    //         endDate
    //     }
    // });

    // return response.data;
    // todo: transform BE data to calendar events and remove the mock below
    const educationLevels = EducationLevelGroupList[educationLevelGroup as EducationLevelGroup];
    const lessonDuration = GradeRangeToLessonDuration[educationLevelGroup as EducationLevelGroup];
    const eventsMock = CALENDAR_EVENTS_MOCK.filter((event) =>
        event.educationLevels!.some((level) => educationLevels.some(({ id }) => id === level))
    ).map((event) => ({
        ...event,
        end: new Date(new Date(event.start as Date).getTime() + lessonDuration * 1000 * 60)
    }));
    return new Promise((resolve) => setTimeout(() => resolve(eventsMock), 100));
};

export default { getGroupList };
