import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Grid, Box, Link, Divider } from '@mui/material';

import { sectionTitleStyle, smallGrayFontStyle } from 'src/styles';
import { ChildFormFields } from 'src/constants';
import { getSpecialRequirementsString, getDisabilityString, getSocialStatusString } from '../helpers';
import InstitutionData from './InstitutionData';

export const EducationData: React.FC = () => {
    const record = useRecordContext();
    const visit = record?.[ChildFormFields.VISIT];
    const specialRequirements = visit?.[ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS]?.parameters;

    const isDisability = visit?.[ChildFormFields.DISABILITY];
    const disabilityGroup = visit?.[ChildFormFields.DISABILITY_GROUP];
    const disabilityReason = visit?.[ChildFormFields.DISABILITY_REASON];

    const mediaRecordingLink = visit?.[ChildFormFields.MEDIA_RECORDING_LINK];
    return (
        <>
            <Grid item xs={12}>
                <Box sx={sectionTitleStyle}>Відомості про освіту дитини в Школі Супергероїв</Box>
            </Grid>

            <InstitutionData />

            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                    Особливі освітні потреби:
                </Box>
                <Box>{getSpecialRequirementsString(specialRequirements) || '-'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                    Дитина має інвалідність
                </Box>
                <Box>{getDisabilityString(isDisability, disabilityGroup, disabilityReason) || '-'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                    Соціальний статус
                </Box>
                <Box>{getSocialStatusString(visit) || '-'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                    Згода на проведення фото-, кіно-, теле- та відеозйомки дитини
                </Box>
                {mediaRecordingLink ? (
                    <Box>
                        Так,{' '}
                        <Link href={mediaRecordingLink} underline="always" target="_blank" rel="noreferrer">
                            Ознайомитись зі згодою
                        </Link>
                    </Box>
                ) : (
                    '-'
                )}
            </Grid>
            <Grid item xs={6}>
                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.4}>
                    Додаткова інформація яку необхідно знати
                </Box>
                <Box>{visit?.info || '-'}</Box>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
        </>
    );
};

export default EducationData;
