import { UserRole } from 'src/auth/types';
import { EducationLevelGroup } from 'src/constants';
import { LessonsDuration } from 'src/constants/centers';

export const GradeRangeToLessonDuration = {
    [EducationLevelGroup.PRESCHOOL]: LessonsDuration.MINUTES30,
    [EducationLevelGroup.JUNIOR_SCHOOL]: LessonsDuration.MINUTES45,
    [EducationLevelGroup.HIGH_SCHOOL]: LessonsDuration.MINUTES60
};

export const EducationLevelToRolesMap = {
    [EducationLevelGroup.PRESCHOOL]: [
        UserRole.PRESCHOOL_TEACHER,
        UserRole.ASSISTANT_PRESCHOOL_TEACHER,
        UserRole.PSYCHOLOGIST,
        UserRole.SPEECH_THERAPIST,
        UserRole.INTELLECTUAL_DISABILITY_TEACHER,
        UserRole.VISION_TEACHER,
        UserRole.DEAF_TEACHER
    ],
    [EducationLevelGroup.JUNIOR_SCHOOL]: [
        UserRole.JUNIORS_TEACHER,
        UserRole.ASSISTANT_JUNIORS_TEACHER,
        UserRole.PSYCHOLOGIST,
        UserRole.SPEECH_THERAPIST,
        UserRole.INTELLECTUAL_DISABILITY_TEACHER,
        UserRole.VISION_TEACHER,
        UserRole.DEAF_TEACHER
    ],
    [EducationLevelGroup.HIGH_SCHOOL]: [
        UserRole.TEACHER,
        UserRole.PSYCHOLOGIST,
        UserRole.SPEECH_THERAPIST,
        UserRole.INTELLECTUAL_DISABILITY_TEACHER,
        UserRole.VISION_TEACHER,
        UserRole.DEAF_TEACHER
    ]
};
