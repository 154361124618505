import * as React from 'react';
import { TextField, useRecordContext, useRedirect, useCreatePath } from 'react-admin';
import { Grid, Box, Divider, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { sectionTitleStyle, smallGrayFontStyle, extraSmallGrayFontStyle } from 'src/styles';
import { ShsButton } from 'src/components/common';
import { EmployeeDocumentLink } from './EmployeeDocumentLink';
import { EmployeeFormFields } from 'src/constants/employee';
import { EmploymentEndDate } from './EmploymentEndDate';
import { StaffRate, StaffRateLabel } from 'src/constants';

/**
 * Interface representing work hours
 */
interface WorkHours {
    from?: string;
    to?: string;
}

/**
 * Interface representing the possible work schedule for a week
 */
interface PossibleWorkSchedule {
    monday: WorkHours[];
    tuesday: WorkHours[];
    wednesday: WorkHours[];
    thursday: WorkHours[];
    friday: WorkHours[];
    saturday: WorkHours[];
    sunday: WorkHours[];
}

/**
 * Props for the WorkSchedule component
 */
interface WorkScheduleProps {
    possibleWorkSchedule?: PossibleWorkSchedule;
}

/**
 * Font styles for table headers and rows
 */
const headerFont = {
    fontWeight: '600',
    color: '#000'
} as const;

const rowsFont = {
    fontWeight: '500'
} as const;

/**
 * Component to display employee work rate information in a table format
 */
const EmployeeWorkGrid: React.FC<{ selectedCenterId: string; currentEmployeeCenter: any }> = ({
    currentEmployeeCenter
}) => {
    const employeeRate = {
        id: currentEmployeeCenter?.id,
        dateOfStarting: currentEmployeeCenter?.dateOfStarting,
        staffRate: StaffRateLabel[currentEmployeeCenter?.rate as StaffRate]
    };

    return (
        <TableContainer component={Paper} sx={{ maxWidth: 650 }}>
            <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#F9FAFB' }}>
                    <TableRow>
                        <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>Дата становлення ставки</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={[extraSmallGrayFontStyle, headerFont]}>Розмір ставки</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={`${employeeRate.id}`}>
                        <TableCell
                            sx={{
                                ...extraSmallGrayFontStyle,
                                ...rowsFont,
                                borderRight: '1px solid #e0e0e0'
                            }}
                            component="th"
                            scope="row">
                            {employeeRate.dateOfStarting}
                        </TableCell>
                        <TableCell sx={[{ ...extraSmallGrayFontStyle, color: '#000' }, rowsFont]}>
                            {employeeRate.staffRate}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

/**
 * Component to display a message indicating no data available for the employee
 */
const EmployeeNoData: React.FC<{ text: string }> = ({ text }) => {
    return (
        <Grid item xs={4}>
            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                {text}
            </Box>
            -
        </Grid>
    );
};

/**
 * Component to display the work schedule for each day of the week
 */
const WorkSchedule: React.FC<WorkScheduleProps> = ({ possibleWorkSchedule }) => {
    const renderSchedule = (dayName: string, schedule?: WorkHours[]) => {
        if (schedule && schedule.length > 0) {
            const { from, to } = schedule[0];
            if (from && to) {
                return (
                    <Box>
                        {dayName}: {`${from} - ${to}`}
                    </Box>
                );
            }
        }
        return null;
    };

    return (
        <Box sx={{ mb: 0.3 }}>
            {renderSchedule('Понеділок', possibleWorkSchedule?.monday)}
            {renderSchedule('Вівторок', possibleWorkSchedule?.tuesday)}
            {renderSchedule('Середа', possibleWorkSchedule?.wednesday)}
            {renderSchedule('Четвер', possibleWorkSchedule?.thursday)}
            {renderSchedule('П’ятниця', possibleWorkSchedule?.friday)}
            {renderSchedule('Субота', possibleWorkSchedule?.saturday)}
            {renderSchedule('Неділя', possibleWorkSchedule?.sunday)}
        </Box>
    );
};

/**
 * Component to display the Employee General Tab view
 */
export const EmployeeGeneralTab: React.FC<{ selectedCenterId: string }> = ({ selectedCenterId }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const createPath = useCreatePath();

    /**
     * Handles the edit button click event
     * Redirects to the edit page for the employee with the specified ID
     */
    const handleEditClick = () => {
        redirect(createPath({ resource: 'employees', type: 'edit', id: record?.id }) + '/2');
    };

    const diplomas = record[EmployeeFormFields.EMPLOYEE_DETAILS]?.diplomas || [];
    const refresherCertificates = record[EmployeeFormFields.EMPLOYEE_DETAILS]?.refresherCertificates || [];
    const additionalDocuments = record[EmployeeFormFields.EMPLOYEE_DETAILS]?.additionalDocuments || [];
    const employees = record[EmployeeFormFields.EMPLOYEES_CENTERS] || [];

    const currentEmployeeCenter = employees.find((employee: any) => {
        const isMatch = employee.educationCenterId == selectedCenterId;
        return isMatch;
    });

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={sectionTitleStyle}>Персональні дані</Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        РНОКПП:
                    </Box>
                    {record[EmployeeFormFields.TAX_NUMBER] ? <TextField source={EmployeeFormFields.TAX_NUMBER} /> : '-'}
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Телефон:
                    </Box>
                    {record[EmployeeFormFields.PHONE] ? <TextField source={EmployeeFormFields.PHONE} /> : '-'}
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Email:
                    </Box>
                    {record[EmployeeFormFields.EMAIL] ? <TextField source={EmployeeFormFields.EMAIL} /> : '-'}
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
                {/* Працевлаштування */}
                <Grid item xs={12}>
                    <Box sx={sectionTitleStyle}>Працевлаштування</Box>
                </Grid>
                {currentEmployeeCenter?.staffMember && (
                    <>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Тип працевлаштування:
                            </Box>
                            Штатний працівник
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Початок трудових відносин:
                            </Box>
                            {currentEmployeeCenter?.dateOfStarting}
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Завершення трудових відносин:
                            </Box>
                            {currentEmployeeCenter?.dateOfEnding ? (
                                currentEmployeeCenter.dateOfEnding
                            ) : (
                                <EmploymentEndDate />
                            )}
                        </Grid>
                        {/* Ставка */}
                        <Grid item xs={12} mb={1}>
                            <Box sx={sectionTitleStyle}>Ставка</Box>
                        </Grid>
                        <EmployeeWorkGrid
                            selectedCenterId={selectedCenterId}
                            currentEmployeeCenter={currentEmployeeCenter}
                        />
                        <Grid item xs={12} mt={2}>
                            <ShsButton
                                styleType="outline"
                                label="Встановити нову ставку"
                                icon={<AddCircleOutlineIcon />}
                                sx={{ height: '48px' }}
                                onClick={handleEditClick}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mb: 2, mt: 2 }} />
                        </Grid>
                    </>
                )}
                {currentEmployeeCenter?.civilLawNatureContract && (
                    <>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Тип працевлаштування:
                            </Box>
                            ЦПХ:
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Початок трудових відносин:
                            </Box>
                            {currentEmployeeCenter?.dateOfStartingCLNC}
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Завершення трудових відносин:
                            </Box>
                            {currentEmployeeCenter?.dateOfEndingCLNC ? (
                                currentEmployeeCenter.dateOfEndingCLNC
                            ) : (
                                <EmploymentEndDate />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Графік роботи:
                            </Box>
                            <WorkSchedule possibleWorkSchedule={currentEmployeeCenter?.possibleWorkSchedule} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mb: 2, mt: 2 }} />
                        </Grid>
                    </>
                )}

                <Grid item xs={12}>
                    <ShsButton
                        styleType="outline"
                        label="Додати тип працевлаштування"
                        icon={<AddCircleOutlineIcon />}
                        sx={{ height: '48px' }}
                        onClick={handleEditClick}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
                {/* Документи */}
                <Grid item xs={12} mb={1}>
                    <Box sx={sectionTitleStyle}>Документи</Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Паспорт
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.passportLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Довідка про реєстрацію місця проживання
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.registrationLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Ідентифікаційний код
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.identificationCodeLink} />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>

            {/* Диплом */}
            {diplomas.length ? (
                diplomas.map((diploma: { diplomaSupplementLink: string; diplomaLink: string }, index: number) => (
                    <Grid container key={index}>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Диплом {index + 1}:
                            </Box>
                            <EmployeeDocumentLink url={diploma.diplomaLink} />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Додаток до диплому - {index + 1}
                            </Box>
                            <EmployeeDocumentLink url={diploma.diplomaSupplementLink} />
                        </Grid>
                    </Grid>
                ))
            ) : (
                <EmployeeNoData text="Диплом" />
            )}
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>

            {/* Сертифікати */}
            <Grid container>
                {refresherCertificates.length ? (
                    refresherCertificates.map((certificate: { certificateLink: string }, index: number) => (
                        <Grid item xs={4} key={index}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                Сертифікати підвищення кваліфікації - {index + 1}
                            </Box>
                            <EmployeeDocumentLink url={certificate.certificateLink} />
                        </Grid>
                    ))
                ) : (
                    <EmployeeNoData text="Сертифікати підвищення кваліфікації" />
                )}
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>

            {/* Інше */}
            <Grid container>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Трудова книжка
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.employmentHistoryLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Медична книжка
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.medicalHistoryLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Документ про зміну ПІБ
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.nameChangeDocumentLink} />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Довідка ВПО
                    </Box>
                    <EmployeeDocumentLink
                        url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.temporarilyDisplacedDocumentLink}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                        Атестаційний лист
                    </Box>
                    <EmployeeDocumentLink url={record[EmployeeFormFields.EMPLOYEE_DETAILS]?.attestationLetterLink} />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>

            {/* Інші документи */}
            <Grid container>
                {additionalDocuments.length ? (
                    additionalDocuments.map((document: { link: string; name: string }, index: number) => (
                        <Grid item xs={4} key={index}>
                            <Box sx={smallGrayFontStyle} mt={3.5} mb={0.3}>
                                {document.name}
                            </Box>
                            <EmployeeDocumentLink url={document.link} />
                        </Grid>
                    ))
                ) : (
                    <EmployeeNoData text="Інший документ" />
                )}
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 2 }} />
                </Grid>
            </Grid>
        </>
    );
};

export default EmployeeGeneralTab;
