import React, { FC } from 'react';
import { EditButton, useCreatePath, useRecordContext, useRedirect } from 'react-admin';

import { ReactComponent as EditIcon } from '../../../assets/pencil-alt.svg';
import { Resource } from '../../../constants';

interface SubjectEditBtnProps {
    refresh: () => void;
}

const SubjectEditBtn: FC<SubjectEditBtnProps> = ({ refresh }) => {
    const record = useRecordContext();
    const createPath = useCreatePath();
    const redirect = useRedirect();

    if (!record) return null;

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        const editPath = createPath({ type: 'edit', resource: Resource.SUBJECTS, id: record.id });
        redirect(editPath);
        setTimeout(refresh, 0);
    };

    return (
        <EditButton
            to={createPath({ type: 'edit', resource: Resource.SUBJECTS, id: record.id })}
            variant="text"
            label=""
            icon={<EditIcon />}
            onClick={handleClick}
        />
    );
};
export default SubjectEditBtn;
