import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { TopToolbar as Toolbar, useCreatePath, useRecordContext, useRedirect } from 'react-admin';

import { UserRoleLabel } from 'src/constants';
import EditIcon from 'src/assets/Edit';
import { ShsButton, ShsSelectInput, StatusBadge } from 'src/components/common';
import { sectionTitleStyle } from 'src/styles';
import { EmployeeFormFields } from 'src/constants/employee';
import PhotoAvatar from '../common/PhotoAvatar';
import axiosApiInstance, { postRequestCacheConfig } from 'src/api/axiosConfig';

/**
 * Toolbar component for displaying employee information and managing their assigned centers
 */
const EmployeeTopToolbar: React.FC<{ onCenterChange: (centerId: string) => void }> = ({ onCenterChange }) => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const record = useRecordContext();

    const [selectedEmployeeCenter, setSelectedEmployeeCenter] = useState<number>(0);
    const [selectedDefaultCenterId, setSelectedDefaultCenterId] = useState<string>('');
    const [centersData, setCentersData] = useState<any[]>([]);
    const [filteredCentersChoices, setFilteredCentersChoices] = useState<any[]>([]);

    const titleName = `${record?.[EmployeeFormFields.LAST_NAME] || ''} ${record?.[EmployeeFormFields.FIRST_NAME] || ''} ${
        record?.[EmployeeFormFields.PATRONYMIC] || ''
    }`;
    const employeeCenters = record?.[EmployeeFormFields.EMPLOYEES_CENTERS];

    /**
     * Redirects to the edit page for the current employee
     */
    const handleEditClick = () => {
        redirect(createPath({ resource: 'employees', type: 'edit', id: record?.id }));
    };

    /**
     * Sets the initially selected employee center index
     */
    useEffect(() => {
        const test = employeeCenters?.length - 1;
        setSelectedEmployeeCenter(test);
    }, [employeeCenters]);

    /**
     * Gets data about the available employee centers
     */
    useEffect(() => {
        const filteredData = centersData
            .filter((value) => employeeCenters?.some((center: any) => center.educationCenterId === value.id))
            .map((value) => ({
                id: value.id.toString(),
                name: value.name
            }));

        setFilteredCentersChoices(filteredData);
    }, [centersData, employeeCenters]);

    /**
     * Fetches employee center data from the API and updates the state with the received data
     */
    useEffect(() => {
        axiosApiInstance
            .post(
                'centers',
                { page: 0, size: 100, sort: { properties: ['shortName'], direction: 'ASC' }, filters: {} },
                postRequestCacheConfig
            )
            .then((response) => {
                setCentersData(response.data.content);
            })
            .catch((e) => {
                throw e;
            });
    }, []);

    /**
     * Sets the default selected center ID and triggers the onCenterChange callback when filtered center choices are available
     */
    useEffect(() => {
        if (filteredCentersChoices.length > 0) {
            setSelectedDefaultCenterId(filteredCentersChoices[0].id.toString());
            if (onCenterChange) {
                onCenterChange(filteredCentersChoices[0].id.toString());
            }
        }
    }, [filteredCentersChoices]);

    /**
     * Handles the change event for the selected education center
     */
    const handleCenterChange = (event: any) => {
        const selectedId = event.target.value;
        if (onCenterChange) {
            onCenterChange(selectedId);
        }

        const selectedCenter = filteredCentersChoices.find((center) => center.id === selectedId);
        if (selectedCenter) {
            const employeeCenter = employeeCenters.find(
                (center: any) => center.educationCenterId.toString() === selectedId
            );
            setSelectedEmployeeCenter(employeeCenters.indexOf(employeeCenter));
        }
    };

    return (
        <Toolbar>
            <Stack display="flex" flexDirection="row" width={1}>
                <Box mr={3}>
                    <PhotoAvatar
                        firstName={record?.[EmployeeFormFields.FIRST_NAME] || ''}
                        lastName={record?.[EmployeeFormFields.LAST_NAME] || ''}
                        url={record?.photo}
                        size="extra-large"
                    />
                </Box>

                <Stack sx={{ flex: '1 1 100%' }}>
                    <Box sx={sectionTitleStyle}>{titleName}</Box>
                    <Box whiteSpace="normal" sx={{ mt: 1.5 }}>
                        <StatusBadge isActive={record?.active} />
                    </Box>
                    <Box mt={1.5}>
                        {selectedDefaultCenterId && (
                            <ShsSelectInput
                                source="selectedDefaultCenterId"
                                choices={filteredCentersChoices}
                                label="Освітній центр"
                                defaultValue={`${selectedDefaultCenterId}`}
                                onChange={handleCenterChange}
                                sx={{ maxWidth: '1000px' }}
                                helperText={false}
                                required
                            />
                        )}
                    </Box>
                    <Box sx={{ marginTop: 1 }}>
                        Штатний працівник:{' '}
                        <span style={{ fontWeight: 'bold' }}>
                            {Object.entries(UserRoleLabel)
                                .filter(([id]) => employeeCenters?.[selectedEmployeeCenter]?.roles?.includes(id))
                                .map(([_, name]) => name)
                                .join(', ')}
                        </span>
                    </Box>
                    {employeeCenters?.[selectedEmployeeCenter]?.rolesCLNC?.length && (
                        <Box sx={{ marginTop: 1 }}>
                            ЦПХ:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {Object.entries(UserRoleLabel)
                                    .filter(([id]) =>
                                        employeeCenters?.[selectedEmployeeCenter]?.rolesCLNC?.includes(id)
                                    )
                                    .map(([_, name]) => name)
                                    .join(', ')}
                            </span>
                        </Box>
                    )}
                </Stack>
                <Box>
                    <ShsButton
                        label="Редагувати"
                        styleType="outline"
                        icon={<EditIcon />}
                        sx={{ height: '40px' }}
                        onClick={handleEditClick}
                    />
                </Box>
            </Stack>
        </Toolbar>
    );
};

export default EmployeeTopToolbar;
