import type { UpdateParams, GetListParams } from 'react-admin';
import axiosApiInstance, { postRequestCacheConfig } from '../../axiosConfig';
import ApiMocks from 'src/api/mocks';
import { childToAPITransform } from 'src/api/Children/transforms';
import { Resource } from 'src/constants/resources';
import type { Child } from 'src/components/Children/types';

export default {
    getList: async (params: GetListParams) => {
        let { keyword, ...filters } = params.filter;
        filters = Object.keys(filters).length ? filters : undefined;

        const pageRequest = {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: params.sort && { properties: [params.sort.field], direction: params.sort.order },
            keyword,
            filters,
            _timestamp: Date.now()
        };

        const response = await axiosApiInstance.post(Resource.CHILDREN, pageRequest, {
            ...postRequestCacheConfig,
            headers: {
                ...postRequestCacheConfig.headers,
                'Cache-Control': 'no-cache'
            }
        });

        return {
            data: response.data.content,
            total: response.data.totalElements
        };
    },

    update: (params: UpdateParams) => {
        const { useMock, method } = params.meta || {};
        if (useMock && ApiMocks[Resource.CHILDREN].update) {
            return Promise.resolve({
                data: { ...ApiMocks[Resource.CHILDREN].getOne, id: params.id }
            });
        }
        const methodUrlPath = method ? `${method.startsWith('/') ? '' : '/'}${method}` : '/update';

        return axiosApiInstance
            .put(`${Resource.CHILDREN}${methodUrlPath}`, childToAPITransform(params.data as Child))
            .then((response) => response);
    }
};
