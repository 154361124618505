import { FC, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Card, Tabs, Tab, Box } from '@mui/material';
import { useQuery } from 'react-query';

import { ScheduleCalendarEvent } from './ScheduleCalendar.types';
import { EducationLevelGroup, Resource, EducationLevelGroupList } from 'src/constants';
import { ScheduleToolbar } from '../ScheduleToolbar/ScheduleToolbar';
import { ScheduleCalendar } from './ScheduleCalendar';
import { useFormContext, useWatch } from 'react-hook-form';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { GradeRangeToLessonDuration } from '../../common/const/Calendar.const';

export const TabbedScheduleCalendar: FC<any> = () => {
    const dataProvider = useDataProvider();
    const { setValue } = useFormContext();
    const { calendarConfig, setCalendarConfig } = useScheduleCalendarContext();
    const [educationLevelGroup, setEducationLevelGroup] = useState<EducationLevelGroup>(
        calendarConfig.educationLevelGroup
    );

    const { data = [] } = useQuery<ScheduleCalendarEvent[]>(educationLevelGroup, () =>
        dataProvider.getGroupList(Resource.SCHEDULE, {
            meta: { educationLevelGroup: educationLevelGroup }
        })
    );

    const handleChangeTab = (_: any, value: EducationLevelGroup) => {
        setEducationLevelGroup(value);
        setValue(
            'educationLevelGroup',
            EducationLevelGroupList[value].map(({ id }) => id)
        );

        // todo: query grade data from BE && update config if needed
        setCalendarConfig((config) => ({
            ...config,
            lessonDuration: GradeRangeToLessonDuration[value]
        }));
    };

    const viewMode = useWatch({
        name: 'viewMode',
        defaultValue: null
    });

    useEffect(() => {
        setCalendarConfig((config) => ({
            ...config,
            viewMode,
            educationLevelGroup
        }));
    }, [viewMode, educationLevelGroup]);

    return (
        <Box>
            <ScheduleToolbar />
            <Card sx={{ boxShadow: 'none' }}>
                <Tabs value={educationLevelGroup} onChange={handleChangeTab}>
                    <Tab value={EducationLevelGroup.PRESCHOOL} label="Дошкільна освіта" />
                    <Tab value={EducationLevelGroup.JUNIOR_SCHOOL} label="1-4 класи" />
                    <Tab value={EducationLevelGroup.HIGH_SCHOOL} label="5-11 класи" />
                </Tabs>
                <ScheduleCalendar events={data} activeTab={educationLevelGroup} />
            </Card>
        </Box>
    );
};
