import { FC, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { omit } from 'lodash';
import { Labeled, RadioButtonGroupInput, useRecordContext, useStore } from 'react-admin';

import { ScheduleEventCard } from '../../common/components/ScheduleEventCard';
import { ShsAutoCompleteWithLoad, ShsCheckboxGroup, ShsSelectInput } from 'src/components/common';
import { Resource, UserRoleLabel } from 'src/constants';
import { UserRole } from 'src/auth/types';
import { DragItem, DragAndDropZone } from 'src/constants/drag-and-drop';
import { Theme, palette } from 'src/Theme';
import { DragAndDropData } from '../../common/interfaces/DragAndDrop.interface';
import { ScheduleCalendarEvent } from '../ScheduleCalendar/ScheduleCalendar.types';
import axiosApiInstance from 'src/api/axiosConfig';
import { shsFormErrorStyles } from 'src/styles';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useScheduleCalendarContext } from '../../common/context/CalendarContext';
import { ScheduleEmployee } from '../../common/interfaces/ScheduleEmployee.interface';
import { ScheduleChild } from '../../common/interfaces/ScheduleChild.interface';
import { EducationLevelGradeRange } from 'src/api/REST/subjects';
import { ScheduleSubject } from '../../common/interfaces/ScheduleSubject.interface';
import DateTimeUtils from 'src/utils/datetime';
import { StoreKey } from 'src/store/enums/store-key';
import { EducationCenterType } from 'src/components/Employees/types';
import { EmployeesMock } from 'src/api/mocks/EmployeesMock';
import { ChildrenMock } from 'src/api/mocks/ChildrenMock';
import { EducationLevelToRolesMap } from '../../common/const/Calendar.const';

const StyledBox = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'highlighted'
})<{ highlighted?: boolean }>(({ highlighted = false }) => {
    return {
        width: '100%',
        minHeight: '54px',
        backgroundColor: highlighted ? palette.primary.gray : palette.primary.grayTint,
        borderRadius: '8px'
    };
});

const StyledLabeled = styled(Labeled)({
    '.RaLabeled-label': {
        fontSize: '14px',
        fontWeight: 600,
        color: palette.primary.midnightBlue
    }
});

const StyledLessonCount = styled(Typography)({
    fontSize: '14px',
    fontWeight: '400',
    color: Theme.palette.primary.grayBluish
});

const StyledHelperText = styled(Typography)({
    fontSize: '11px',
    textAlign: 'center',
    color: Theme.palette.primary.grayBluish
});

const ChildLabel = () => {
    const { name, surname, lessonCount } = useRecordContext();

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>{`${name} ${surname}`}</Typography>
            <StyledLessonCount>{lessonCount}</StyledLessonCount>
        </Stack>
    );
};

const fetchEmployees = async (
    educationLevelRange: string,
    subjectId: ScheduleSubject['id'],
    rangeStart: Date
): Promise<ScheduleEmployee[]> => {
    // todo: different user role should call different endpoint?
    const params = new URLSearchParams({
        targetGradeRange: educationLevelRange,
        subjectId: subjectId.toString(),
        weekDate: DateTimeUtils.getFormattedDate(rangeStart)
    });

    try {
        // const { data } = await axiosApiInstance.get(`employees/teachers?${params.toString()}`); // todo: replace with actual call
        const data = EmployeesMock;
        return data;
    } catch (e) {
        console.error(e);
        return [];
    }
};

const fetchChildren = async (
    educationLevelRange: string,
    subjectId: ScheduleSubject['id'],
    rangeStart: Date
): Promise<ScheduleChild[]> => {
    const params = new URLSearchParams({
        targetGradeRange: educationLevelRange,
        subjectId: subjectId.toString(),
        weekDate: DateTimeUtils.getFormattedDate(rangeStart)
    });

    try {
        // const { data } = await axiosApiInstance.get(`children?${params.toString()}`); // todo: replace with actual call
        const data = ChildrenMock;
        return data;
    } catch (e) {
        console.error(e);
        return [];
    }
};

const fetchEducationCenters = async (): Promise<EducationCenterType[]> => {
    try {
        const { data } = await axiosApiInstance.post(`centers`, {
            page: 0,
            size: 9999
        });

        return data.content;
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const ScheduleSidebar: FC = () => {
    const form = useForm();
    const { calendarConfig, setCalendarData } = useScheduleCalendarContext();
    const [userRoleOptions, setUserRoleOptions] = useState<{ name: string; id: string }[]>([]);

    const [educationalCenterId] = useStore(StoreKey.EDUCATIONAL_CENTER);

    const [educationalCenters, setEducationalCenters] = useState<EducationCenterType[]>([]);
    const [educationLevelRange, setEducationLevelRange] = useState(EducationLevelGradeRange.juniorSchool);
    const [events, setEvents] = useState<ScheduleCalendarEvent[]>([]); // should be only one event at a time?
    const [employees, setEmployees] = useState<ScheduleEmployee[]>([]);
    const [children, setChildren] = useState<ScheduleChild[]>([]);

    const role: UserRole = useWatch({ name: 'role', control: form.control, defaultValue: UserRole.TEACHER });
    const [subject, setSubject] = useState<ScheduleSubject | null>(null);
    const employeeId: number = useWatch({ name: 'employee', control: form.control, defaultValue: null });
    const childrenIds: number[] = useWatch({
        name: 'children',
        control: form.control,
        defaultValue: []
    });

    const [{ highlighted }, dropRef] = useDrop(
        () => ({
            accept: DragItem.SCHEDULE_CARD,
            drop: (item: any) => handleScheduleEventDrop(item),
            collect: (monitor) => ({
                highlighted: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }),
        [events]
    );

    const resetSidebar = (options: { role?: boolean; subject?: boolean; employee?: boolean; children?: boolean }) => {
        const { role, subject, employee, children } = options;

        setEvents([]);

        if (role) {
            form.resetField('role', { defaultValue: UserRole.TEACHER });
        }
        if (subject) {
            form.resetField('subject', { defaultValue: null });
            setSubject(null);
        }
        if (employee) {
            form.resetField('employee', { defaultValue: null });
            setEmployees([]);
        }
        if (children) {
            form.resetField('children', { defaultValue: [] });
            setChildren([]);
        }
    };

    useEffect(() => {
        if (calendarConfig.educationLevelGroup) {
            setUserRoleOptions(
                EducationLevelToRolesMap[calendarConfig.educationLevelGroup].map((userRole) => ({
                    id: userRole,
                    name: UserRoleLabel[userRole]
                }))
            );
        }
    }, [calendarConfig.educationLevelGroup]);

    useEffect(() => {
        fetchEducationCenters().then((educationCenters) => {
            setEducationalCenters(educationCenters);

            const selectedCenter = educationCenters?.find((center) => center.id === educationalCenterId);
            if (selectedCenter) {
                setCalendarData((data) => ({ ...data, educationCenter: selectedCenter }));
            }
        });
    }, []);

    useEffect(() => {
        if (calendarConfig.educationLevelGroup) {
            setEducationLevelRange(EducationLevelGradeRange[calendarConfig.educationLevelGroup]);
        }

        resetSidebar({
            role: true,
            subject: true,
            employee: true,
            children: true
        });
    }, [calendarConfig.educationLevelGroup]);

    useEffect(() => {
        resetSidebar({
            subject: true,
            employee: true,
            children: true
        });
    }, [role]);

    useEffect(() => {
        resetSidebar({
            employee: true,
            children: true
        });
        if (subject) {
            fetchEmployees(educationLevelRange, subject.id, calendarConfig.range?.start || new Date()).then(
                (employees) => setEmployees(employees)
            );
        }
    }, [subject, educationLevelRange, calendarConfig.range]);

    useEffect(() => {
        // todo: fix workaround // radio btn group does not emit value on choices arr change
        if (employees.length === 0) {
            form.setValue('employee', null);
        } else {
            form.setValue('employee', employees[0].id);
        }
    }, [employees]);

    useEffect(() => {
        resetSidebar({
            children: true
        });

        if (employeeId && subject) {
            fetchChildren(educationLevelRange, subject.id, calendarConfig.range?.start || new Date()).then((children) =>
                setChildren(children)
            );
        }
    }, [employeeId, subject, calendarConfig.range]);

    useEffect(() => {
        setCalendarData((calendarData) => ({
            ...calendarData,
            children: [],
            employees: []
        }));
        resetSidebar({});

        if (employeeId !== null && subject !== null) {
            const employee = employees.find((employee) => +employee.id === +employeeId);

            const selectedChildren: ScheduleChild[] = childrenIds
                .filter(Boolean)
                .map((childId) => children.find((child) => child.id === childId)) as ScheduleChild[];

            setCalendarData((calendarData) => ({
                ...calendarData,
                children: selectedChildren,
                employees: employee ? [employee] : []
            }));

            setEvents((events) => {
                const id = [subject.id, employeeId, ...selectedChildren.map((child) => child.id)].join('_');
                // todo: replace with one event only if multiple events not possible
                const event: ScheduleCalendarEvent = events.length > 0 ? { ...events[0] } : { id, title: '' };

                event.title = subject.name;
                event.employee = employee;
                event.children = selectedChildren;

                return [event];
            });
        }
    }, [employeeId, childrenIds, subject]);

    const handleScheduleEventDrop = (item: ScheduleCalendarEvent) => {
        const itemIndex = events.findIndex((event) => event.id === item.id);
        if (itemIndex === -1) {
            const updatedEvent = omit(item, ['start', 'end']);

            setEvents((events) => [...events, updatedEvent]);
            // todo: allow dragging already existing calendar items?
        }

        return { item, target: DragAndDropZone.SIDEBAR };
    };

    const handleScheduleEventDragEnd = ({ item, target }: DragAndDropData) => {
        if (target === DragAndDropZone.TIMESLOT) {
            // todo: update data on BE here
            setEvents((events) => events.filter((event) => event.id !== item.id));
        }
    };

    const handleSubjectChange = (_: any, subject: unknown) => {
        setSubject(subject ? (subject as ScheduleSubject) : null);
    };

    return (
        <Stack sx={{ position: 'sticky', top: 80 + 16 }}>
            <FormProvider {...form}>
                {/* todo: render a list of educational centers for admin users */}
                <ShsSelectInput
                    source="role"
                    label="Для кого складаєте розклад"
                    placeholder="Виберіть роль"
                    choices={userRoleOptions}
                    required
                />

                {role && (
                    <ShsAutoCompleteWithLoad
                        source="subject"
                        pageUrl={Resource.SUBJECTS}
                        keyField="id"
                        textField={({ name }) => name.trim()}
                        label="Предмет"
                        resultLimit={1000}
                        multiselect={false}
                        filters={{ [calendarConfig.educationLevelGroup as string]: true }}
                        onChange={handleSubjectChange}
                        required
                    />
                )}
                {subject && (
                    <StyledLabeled label={UserRoleLabel[role]} key={employees.length}>
                        {employees.length > 0 ? (
                            <RadioButtonGroupInput
                                source="employee"
                                defaultValue={employees?.[0]?.id || null}
                                defaultChecked={true}
                                parse={Number}
                                choices={employees.map(({ id, name, surname }) => ({ id, name: `${name} ${surname}` }))}
                                label=""
                                autoFocus={false}
                                sx={{ ...shsFormErrorStyles, m: 0, flexDirection: 'column' }}
                            />
                        ) : (
                            <StyledHelperText>Працівника не знайдено</StyledHelperText>
                        )}
                    </StyledLabeled>
                )}
                {/* TODO: add grouping by class // how? */}
                {subject && employeeId && children.length > 0 && (
                    <StyledLabeled label="Клас">
                        <ShsCheckboxGroup
                            source="children"
                            choices={children.map(({ id, name, surname, lessonCount }) => ({
                                id,
                                name,
                                surname,
                                lessonCount
                            }))}
                            optionText={<ChildLabel />}
                            sx={{
                                '.MuiFormControlLabel-label': {
                                    width: '100%'
                                }
                            }}
                        />
                    </StyledLabeled>
                )}
            </FormProvider>

            {subject && employeeId && children.length > 0 && events.length > 0 && (
                // todo: should allow dragging schedule item back to the sidebar?
                <StyledBox ref={dropRef} highlighted={highlighted}>
                    {events.map((event) => (
                        <ScheduleEventCard
                            key={event.id}
                            event={event}
                            dragIcon
                            enabled={!!event.children?.length && !!event.employee}
                            onDragEnd={handleScheduleEventDragEnd}
                        />
                    ))}
                </StyledBox>
            )}

            {events.length > 0 && (
                <StyledHelperText>Щоб додати урок, перетягніть картку на потрібну годину</StyledHelperText>
            )}

            {employeeId && subject && events.length === 0 && (
                <StyledHelperText>Всі уроки з цього предмету додані в розклад</StyledHelperText>
            )}
        </Stack>
    );
};
