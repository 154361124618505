import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Create, SimpleForm, useCreatePath, useNotify, useRedirect } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { ChildFormDefaults } from 'src/constants';
import { editContainerStyles } from 'src/styles';
import { Theme } from 'src/Theme';

import AddToolbar from './Toolbar';
import { PersonalData, EducationInstitutionData, VisitorsList, EducationChildData } from './FieldGroups';
import { CancelEditModal } from '../modals';
import { ValidationErrorModal } from '../modals/ValidationErrorModal';

/**
 * Component for adding a new child's profile
 */
export const ChildAdd: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isValidationErrorModalOpen, setValidationErrorModalOpen] = useState(false);
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const notify = useNotify();
    const form = useForm();

    /**
     * Callback function executed on successful creation of a child profile
     * Displays a success notification and redirects to the children list
     */
    const onSuccess = () => {
        notify('Дитину успішно додано', {
            type: 'success',
            autoHideDuration: 2500
        });
        redirect(createPath({ resource: 'children', type: 'list' }));
    };

    /**
     * Opens the cancel modal
     */
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    /**
     * Closes the cancel modal
     */
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    /**
     * Redirects to the children list on cancel action
     */
    const handleCancel = () => {
        redirect(createPath({ resource: 'children', type: 'list' }));
    };

    /**
     * Handles form validation error
     * Opens validation error modal
     */
    const handleValidationError = () => {
        setValidationErrorModalOpen(true);
        setIsModalOpen(false);
    };

    /**
     * Closes the validation error modal
     */
    const handleCloseValidationErrorModal = () => {
        setValidationErrorModalOpen(false);
    };

    return (
        <FormProvider {...form}>
            <Container sx={editContainerStyles} maxWidth={false}>
                <ShsReturnButton
                    label="Назад до списку дітей"
                    sx={{
                        fontSize: Theme.typography.body2.fontSize,
                        color: Theme.palette.primary.grayBluish
                    }}
                    onClick={handleOpenModal}
                />
                <Create mutationOptions={{ onSuccess }}>
                    <Typography fontSize={'40px'} mb={4} fontWeight="700" color={Theme.palette.primary.midnightBlue}>
                        Додати дитину
                    </Typography>
                    <SimpleForm defaultValues={ChildFormDefaults} toolbar={<AddToolbar handleCancel={handleCancel} />}>
                        <PersonalData />
                        <EducationInstitutionData />
                        <VisitorsList />
                        <EducationChildData />

                        {isModalOpen && (
                            <CancelEditModal
                                isOpen={isModalOpen}
                                onCancel={handleCancel}
                                onClose={handleCloseModal}
                                handleValidationError={handleValidationError}
                            />
                        )}

                        {isValidationErrorModalOpen && (
                            <ValidationErrorModal
                                isOpen={isValidationErrorModalOpen}
                                onClose={handleCloseValidationErrorModal}
                            />
                        )}
                    </SimpleForm>
                </Create>
            </Container>
        </FormProvider>
    );
};
