import { FC } from 'react';
import { useDrag } from 'react-dnd';
import { Box, Card, Typography } from '@mui/material';
import { ReactComponent as ReorderIcon } from 'src/assets/reorder.svg';
import { palette } from 'src/Theme';
import DateTimeUtils from 'src/utils/datetime';
import { DragItem, DragAndDropZone } from 'src/constants/drag-and-drop';
import { DragAndDropData } from '../interfaces/DragAndDrop.interface';
import { ReactComponent as UsersIcon } from 'src/assets/users.svg';
import { UnknownAvatar } from 'src/components/common';
import { EducationLevel, EducationLevelGroup, EducationLevelGroupList } from 'src/constants';
import { hashCode } from 'src/utils';
import { ScheduleCalendarEvent } from '../../List/ScheduleCalendar/ScheduleCalendar.types';

const preschoolLabels = EducationLevelGroupList[EducationLevelGroup.PRESCHOOL].map(({ id }) => id);
const schoolLabels = [
    ...EducationLevelGroupList[EducationLevelGroup.JUNIOR_SCHOOL].map(({ id }) => id),
    ...EducationLevelGroupList[EducationLevelGroup.HIGH_SCHOOL].map(({ id }) => id)
];

function getEducationLevelLabel(educationLevels: EducationLevel[]): string {
    let label: string[] = [];

    const preschoolEducation = educationLevels.filter((level) => preschoolLabels.includes(level));
    const schoolEducation = educationLevels.filter((level) => schoolLabels.includes(level));

    if (preschoolEducation.length > 0) {
        label.push('дошкільна освіта');
    }

    if (schoolEducation.length > 0) {
        const uniqueGrades = Array.from(new Set(schoolEducation)).sort((a, b) => {
            const aNumber = parseInt(a.substring('GRADE'.length));
            const bNumber = parseInt(b.substring('GRADE'.length));

            return aNumber - bNumber;
        });

        const labels = uniqueGrades.map((grade) => {
            const number = parseInt(grade.substring('GRADE'.length));
            return number.toString();
        });

        label.push(`клас${labels.length > 1 ? 'и' : ''} ${labels.join(',')}`);
    }

    return label.join(', ');
}

export const ScheduleEventCard: FC<{
    event: ScheduleCalendarEvent;
    enabled?: boolean;
    dragIcon?: boolean;
    onDragEnd?: (data: DragAndDropData) => void;
}> = ({ event, dragIcon: dragIcon, onDragEnd, enabled = true }) => {
    const { title, start, end, educationLevels, children, employee } = event;

    // todo: use dragPreviewRef to show rotated preview
    const [{ isDragging }, dragRef, dragPreviewRef] = useDrag(
        () => ({
            type: DragItem.SCHEDULE_CARD,
            item: { ...event },
            end: (_, monitor) => {
                const dropResult = monitor.getDropResult<{ target: DragAndDropZone }>();

                if (dropResult && onDragEnd) {
                    onDragEnd({ item: event, target: dropResult.target });
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }),
        [event]
    );

    return (
        <Card
            ref={enabled ? dragRef : null}
            sx={{
                display: 'flex',
                gap: '8px',
                backgroundColor: enabled
                    ? palette.lessonColors[Math.abs(hashCode(title?.toString() || '') % palette.lessonColors.length)]
                    : '#9B9FA2',
                padding: '4px 8px',
                cursor: enabled ? 'grab' : 'not-allowed',
                height: '100%',
                userSelect: 'none',
                minHeight: '54px'
            }}>
            {dragIcon && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ReorderIcon />
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    minWidth: 0,
                    borderRadius: '8px',
                    color: palette.primary.creamyWhite,
                    fontSize: '10px',
                    width: '100%'
                }}>
                <Typography
                    component="b"
                    sx={{
                        maxWidth: '100%',
                        fontWeight: 600,
                        fontSize: '11px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>
                    {title}
                </Typography>
                {start && end && (
                    <Typography
                        component="time"
                        sx={{ fontSize: '11px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {DateTimeUtils.getTime(start)} - {DateTimeUtils.getTime(end)}
                    </Typography>
                )}
                <Box sx={{ display: 'flex', gap: '14px', width: '100%' }}>
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            flexShrink: 1
                        }}>
                        {getEducationLevelLabel(educationLevels || []) || '-'}
                    </span>
                    <span style={{ display: 'flex', gap: '2px', flexShrink: 0 }}>
                        {/* todo: pluralization via polyglot.js */}
                        <UsersIcon /> {children?.length || '-'} {children?.length ? 'учнів' : ''}
                    </span>
                </Box>
                <Box sx={{ display: 'flex', gap: '2px', alignItems: 'center', justifyContent: 'center' }}>
                    {employee && (
                        <>
                            <UnknownAvatar firstName={employee.name} lastName={employee.surname} size="extra-small" />
                            <Typography variant="inherit">
                                {employee.name} {employee.surname}
                            </Typography>
                        </>
                    )}
                    {!employee && <Typography variant="inherit">-</Typography>}
                </Box>
            </Box>
        </Card>
    );
};
