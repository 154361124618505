import { FC } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { ScheduleCalendarProvider } from '../common/context/CalendarContext';
import { ScheduleListView } from './ScheduleListView';

export const ScheduleList: FC = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <ScheduleCalendarProvider>
                <ScheduleListView />
            </ScheduleCalendarProvider>
        </DndProvider>
    );
};
