import React, { CSSProperties } from 'react';

import { SIZE_CONFIG } from 'src/constants';
import UnknownAvatar from './UnknownAvatar';

/**
 * Props for PhotoAvatar component
 */
export type PhotoAvatarProps = {
    firstName: string;
    lastName: string;
    url?: string;
    size: keyof typeof SIZE_CONFIG;
    style?: CSSProperties;
};

/**
 * PhotoAvatar component displays a user's avatar, if no image provided - renders Unknown avatar
 */
const PhotoAvatar: React.FC<PhotoAvatarProps> = ({ url, size = 'small', firstName, lastName, style = {} }) => {
    const sizeConfig = SIZE_CONFIG[size];

    const isValidUrl = (url: string = '') => {
        const pattern = /^(https?:\/\/)[\w\-]+(\.[\w\-]+)+/;
        return pattern.test(url);
    };

    return isValidUrl(url) ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: sizeConfig.circle,
                minWidth: sizeConfig.circle,
                height: sizeConfig.circle,
                minHeight: sizeConfig.circle,
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                fontSize: sizeConfig.fontSize,
                borderRadius: '50%',
                fontWeight: '800',
                ...style
            }}></div>
    ) : (
        <UnknownAvatar lastName={lastName} firstName={firstName} size={size} style={{ marginRight: '12px' }} />
    );
};

export default PhotoAvatar;
